import React, { useState, useEffect, Fragment } from 'react';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Checkbox, Button, Input, notification, Spin, Form, Tooltip, Radio } from 'antd';
import api from '../../api';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ValueType, OptionsType, OptionTypeBase } from 'react-select';
import { QuestionCircleOutlined } from '@ant-design/icons';
import * as ct from 'countries-and-timezones';

/** App theme */
import { colors } from '../../Themes/Colors';

/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

const langOptions = [
  { value: 'en-US', label: 'English' },
  // { value: 'en-GB', label: 'UK English' },
  // { value: 'en-ZA', label: 'South African English' },
  // { value: 'en-AU', label: 'Australian English' },
  // { value: 'en-IN', label: 'Indian English' },
  { value: 'fr-FR', label: 'French (European)' },
  { value: 'fr-CA', label: 'French (Canadian)' },
  { value: 'es-ES', label: 'Spanish (European)' },
  { value: 'es-MX', label: 'Spanish (Latin American)' },
  // { value: 'pt-PT', label: 'Portuguese (European)' },
  { value: 'pt-BR', label: 'Brazilian (Portuguese)' },
  // { value: 'it', label: 'Italian' },
];

const timeZoneOptions = Object.values(ct.getAllTimezones()).map((x) => {
  x['label'] = x['name'];
  x['value'] = x['name'];
  return x;
});
const PreferencesContainer = () => {
  const [state, updateState] = useState({
    loading: false,
    showSendViaEmail: false,
    userEmail: '',
  });
  const [vidState, updateVidState] = useState({
    videoInCloudValue: null,
  });
  // provides access to the form contents with some handy methods
  const [form] = Form.useForm();

  useEffect(() => {
    const fn = async () => {
      try {
        const userId = localStorage.getItem('sub');
        const user = await api.get(`/account/${userId}`);
        updateState({ ...state, userEmail: user.data.Attributes.email });
        api
          .get(`account/${userId}/preferences`)
          .then(async (response) => {
            form.setFieldsValue({
              useZoomTranscript: response.data.useZoomTranscript ? response.data.useZoomTranscript : false,
              deleteTempWavFile: response.data.deleteTempWavFile ? response.data.deleteTempWavFile : false,
              attachStToEmail: response.data.attachStToEmail ? response.data.attachStToEmail : false,
              sendViaEmailAddress: response.data.sendViaEmailAddress ? response.data.sendViaEmailAddress : '',
              putVideoInCloud: response.data.putVideoInCloud ? response.data.putVideoInCloud : 1,
              putStInCloud: response.data.putStInCloud ? response.data.putStInCloud : 0,
              customTopics: response.data.customTopics ? response.data.customTopics : [],
              customVocabulary: response.data.customVocabulary ? response.data.customVocabulary : [],
              primaryLanguage: response.data.primaryLanguage ? response.data.primaryLanguage : {},
              timezone: response.data.timezone ? response.data.timezone : {},
            });
            //console.log(response.data.sendViaEmailAddress);
            updateVidState({ ...vidState, videoInCloudValue: response.data.putVideoInCloud });

            if (response.data.sendViaEmailAddress) {
              updateState({ ...state, showSendViaEmail: true });
              form.setFieldsValue({
                showSendViaEmailAddress: true,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (e) {
        console.error(e);
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handles the onSubmit form event when validation has passed on the form fields
  const handleOnFinish = async (values) => {
    const userId = localStorage.getItem('sub');

    // eslint-disable-next-line
    let {
      useZoomTranscript,
      deleteTempWavFile,
      attachStToEmail,
      sendViaEmailAddress,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      putVideoInCloud,
      putStInCloud,
      customTopics,
      customVocabulary,
      primaryLanguage,
      timezone,
    } = values;
    updateState({ ...state, loading: true });
    api
      .post(`account/${userId}/preferences/`, {
        useZoomTranscript,
        deleteTempWavFile,
        attachStToEmail,
        sendViaEmailAddress,
        putVideoInCloud,
        putStInCloud,
        customTopics: customTopics ? customTopics : [],
        customVocabulary: customVocabulary ? customVocabulary : [],
        primaryLanguage: primaryLanguage ? primaryLanguage : {},
        timezone: timezone ? timezone : {},
      })
      .then(async (response) => {
        updateState({ ...state, loading: false });
        notification.success({
          message: 'Preferences saved successfully!',
          placement: 'topRight',
          duration: 3.5,
        });
      })
      .then()
      .catch((err) => {
        updateState({ ...state, loading: false });
        console.error(err, err.response);
        notification.error({
          message: `Error ${err.response.data.message}`,
          description: err.response.data.details.body[0].message,
          placement: 'topRight',
          duration: 3.5,
        });
      });
  };

  // handles the onSubmit form event when validation has failed on the form fields
  const handleOnFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const isValidTopicOption = (
    inputValue: string,
    value: ValueType<OptionTypeBase, true>,
    options: OptionsType<OptionTypeBase>
  ): boolean => {
    if (inputValue === '') {
      return false;
    }
    return value && value.length >= 50 ? false : true;
  };

  // const isValidVocabularyOption = (
  //   inputValue: string,
  //   value: ValueType<OptionTypeBase>,
  //   options: OptionsType<OptionTypeBase>
  // ): boolean => {
  //   if (inputValue === '') {
  //     return false;
  //   }
  //   return value && value.length >= 100 ? false : true;
  // };

  const onEmailCheckboxChange = (e) => {
    updateState({ ...state, showSendViaEmail: e.target.checked });
    if (e.target.checked === false) {
      form.setFieldsValue({
        sendViaEmailAddress: '',
      });
    }
  };

  const onPutVideoRadioChange = (e) => {
    updateVidState({ ...vidState, videoInCloudValue: e.target.value });
    form.setFieldsValue({
      putVideoInCloud: e.target.value,
    });
  };

  const onPutStRadioChange = (e) => {
    form.setFieldsValue({
      putStInCloud: e.target.value,
    });
  };

  return (
    <Fragment>
      <FormWrapper onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} form={form}>
        <span>Time Zone</span>
        <Form.Item name="timezone" valuePropName="value">
          <Select options={timeZoneOptions} />
        </Form.Item>
        <span>Language</span>
        <Form.Item name="primaryLanguage" valuePropName="value">
          <Select options={langOptions} />
        </Form.Item>
        <span>Custom Topics</span>
        <Form.Item name="customTopics" valuePropName="value">
          <CreatableSelect
            isMulti
            noOptionsMessage={() => 'Type to add'}
            isValidNewOption={isValidTopicOption}
            isClearable={true}
          />
        </Form.Item>
        {/*
        <span>Custom Vocabulary</span>
        <Form.Item name="customVocabulary" valuePropName="value">
          <CreatableSelect
            isMulti
            noOptionsMessage={() => 'Type to add'}
            isValidNewOption={isValidVocabularyOption}
            isClearable={true}
          />
        </Form.Item> 
        */}

        <Form.Item label="Use Zoom Transcript">
          <Form.Item noStyle name="useZoomTranscript" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Tooltip title="">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://support.meetmyna.com/hc/en-us/articles/360014571400-Zoom-Set-up-Cloud-Recording"
              style={{ margin: '0 8px' }}
            >
              <QuestionCircleOutlined translate="yes" />
            </a>
          </Tooltip>
        </Form.Item>
        <Form.Item label="Delete temporary Recorder WAV file:">
          <Form.Item noStyle name="deleteTempWavFile" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Tooltip title="">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://support.meetmyna.com/hc/en-us/sections/360004237259-Recording-with-Myna"
              style={{ margin: '0 8px' }}
            >
              <QuestionCircleOutlined translate="yes" />
            </a>
          </Tooltip>
        </Form.Item>
        <Form.Item name="showSendViaEmailAddress" valuePropName="checked" label=" Send to an alternative email">
          <Checkbox checked={state.showSendViaEmail} onChange={onEmailCheckboxChange} />
        </Form.Item>
        {state.showSendViaEmail ? (
          <Form.Item name="sendViaEmailAddress" rules={[{ required: false, message: 'Please input an email!' }]}>
            <Input
              prefix={<UserOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              placeholder={state.userEmail}
            />
          </Form.Item>
        ) : null}

        <Form.Item label="Store Video in " name="putVideoInCloud">
          <Radio.Group onChange={onPutVideoRadioChange} value={vidState.videoInCloudValue}>
            <Radio value={1}>Dropbox</Radio>
            <Radio value={2}>OneDrive (Personal)</Radio>
            <Radio value={3}>Microsoft Business</Radio>
          </Radio.Group>
          <Tooltip title="">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://support.meetmyna.com/hc/en-us/articles/360015536360-Why-do-I-need-to-connect-Cloud-Storage-"
              style={{ margin: '0 8px' }}
            >
              <QuestionCircleOutlined translate="yes" />
            </a>
          </Tooltip>
        </Form.Item>

        <Form.Item
          name="putStInCloud"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                //console.log(getFieldValue('attachStToEmail'));
                //console.log('this', value);
                if (getFieldValue('attachStToEmail') === false && value === 3) {
                  return Promise.reject(
                    'One of Store a copy of SmartTranscript in Cloud or Attach SmartTranscript to email is required'
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          label="Store a copy of SmartTranscript in "
        >
          <Radio.Group onChange={onPutStRadioChange}>
            <Radio value={0}>None</Radio>
            <Radio value={1}>Cloud</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="attachStToEmail" valuePropName="checked" label="Attach SmartTranscript to email">
          <Checkbox />
        </Form.Item>
        <Button type="primary" disabled={state.loading} htmlType="submit">
          {state.loading ? (
            <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
          ) : (
            'Save'
          )}
        </Button>
      </FormWrapper>
    </Fragment>
  );
};

export default PreferencesContainer;
