import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Spin, Input, Button, notification, Row, Col, Form, Layout } from 'antd';

/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

/** App theme */
import { colors } from '../../Themes/Colors';

type State = {
  username: string;
  loading: boolean;
};

const ForgotPasswordContainer = () => {
  const [state, updateState] = useState<State>({
    username: '',
    loading: false,
  });

  const [form] = Form.useForm();

  const history = useHistory();

  const handleOnFinish = (values) => {
    let { username } = values;

    updateState({
      ...state,
      loading: true,
      username,
    });

    Auth.forgotPassword(username)
      .then(() => {
        notification.success({
          message: 'Redirecting you in a few!',
          description: 'Account confirmed successfully!',
          placement: 'topRight',
          duration: 2,
          onClose: () => {
            history.push(`/reset-password?username=${username}`);
          },
        });
      })
      .catch((err) => {
        notification.error({
          message: 'User confirmation failed',
          description: err.message,
          placement: 'topRight',
          duration: 4.5,
        });
        updateState({ ...state, loading: false });
      });
  };

  const handleOnFinishedFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const { loading } = state;

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>
      <div style={{ paddingTop: '7%' }}>
        <FormWrapper onFinish={handleOnFinish} onFinishFailed={handleOnFinishedFailed} className="login-form">
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <h1 style={{ fontFamily: 'Brick, Arial, sand-serif', fontSize: '36px', fontWeight: 700 }}>
              Forgot Password
            </h1>
          </div>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input
              prefix={<UserOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item className="text-center">
            <Row>
              <Col lg={24}>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                  {loading ? (
                    <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
                  ) : (
                    'Confirm username'
                  )}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </FormWrapper>
      </div>
    </Layout>
  );
};

export default ForgotPasswordContainer;
