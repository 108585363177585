import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Button, Input, Col, Row, notification, Form, Layout } from 'antd';
import axios from 'axios';

/** App theme */
import { colors } from '../../Themes/Colors';
import FormWrapper from '../../Components/Styled/FormWrapper';
type State = {
  username: string;
  loading: boolean;
  redirect: boolean;
  confirmationCode: string;
  error: string;
};

const ConfirmEmailContainer = (props: RouteComponentProps) => {
  const [state, updateState] = useState<State>({
    username: '',
    loading: false,
    redirect: false,
    confirmationCode: '',
    error: '',
  });

  // on mount, set the username if condition is met
  useEffect(() => {
    updateState({ ...state, username: props.location.search.split('=')[1] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnFinish = () => {
    const { confirmationCode } = state;

    // show progress spinner
    updateState({ ...state, loading: true });

    Auth.confirmSignUp(state.username, confirmationCode)
      .then((data) => {
        handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
      })
      .catch((err) => {
        handleOpenNotification('error', 'Invalid code', err.message);
        updateState({ ...state, loading: false });
      });
  };

  const handleOpenNotification = async (type: string, title: string, message: string): Promise<void> => {
    switch (type) {
      case 'success':
        createNewUserOnBackend();
        notification['success']({
          message: title,
          description: message,
          placement: 'topRight',
          duration: 3,
          onClose: () => updateState({ ...state, redirect: true }),
        });
        break;
      case 'error':
        notification['error']({
          message: title,
          description: message,
          placement: 'topRight',
          duration: 4.5,
        });
        break;
    }
  };

  const handleOnPaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    let code = event.clipboardData.getData('Text').trim();

    // Update input
    updateState({ ...state, confirmationCode: code });

    // regex to check if string is numbers only
    const reg = new RegExp('^[0-9]+$');

    if (reg.test(code) && code.length === 6) {
      // code is a valid number
      updateState({ ...state, loading: true });

      Auth.confirmSignUp(state.username, code)
        .then(() =>
          handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!')
        )
        .catch((err) => {
          handleOpenNotification('error', 'Invalid code', err.message);
          updateState({ ...state, loading: false });
        });
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ ...state, confirmationCode: event.currentTarget.value });
  };

  const createNewUserOnBackend = async () => {
    try {
      return await axios.post(`${process.env.REACT_APP_API_URI}webhook/newUser`, {
        username: state.username,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const { loading, error, confirmationCode, redirect } = state;

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>
      <div style={{ paddingTop: '7%' }}>
        <FormWrapper onFinish={handleOnFinish}>
          <Row>
            <Col lg={24}>
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <h1 style={{ fontFamily: 'Brick, Arial, sand-serif', fontSize: '36px', fontWeight: 700 }}>
                  Verfiy Email
                </h1>
                <span>We've sent a six­ digit confirmation code</span>
              </div>
              <Form.Item validateStatus={error && 'error'} help={error}>
                <Input
                  size="large"
                  type="number"
                  placeholder="Enter confirmation code"
                  onChange={(e) => handleOnChange(e)}
                  onPaste={(e) => handleOnPaste(e)}
                  value={confirmationCode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <Button type="primary" disabled={loading} htmlType="submit" style={{ width: '100%' }}>
                {loading ? (
                  <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
                ) : (
                  'Confirm Email'
                )}
              </Button>
            </Col>
          </Row>
        </FormWrapper>
      </div>
      {redirect && <Redirect to={{ pathname: '/login' }} />}
    </Layout>
  );
};

export default ConfirmEmailContainer;
