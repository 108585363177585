import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Spin, Input, Button, notification, Col, Row, Form, Layout } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import axios from 'axios';
import api from '../../api';

/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

/** App theme */
import { colors } from '../../Themes/Colors';

type State = {
  loading: boolean;
};

const LoginContainer = () => {
  const [state, updateState] = useState<State>({
    loading: false,
  });
  const history = useHistory();
  const location = useLocation();
  // provides access to the form contents with some handy methods
  const [form] = Form.useForm();

  // handles the onSubmit form event when validation has passed on the form fields
  const handleOnFinish = (values) => {
    let { username, password } = values;

    updateState({ ...state, loading: true });

    api
      .post(`${process.env.REACT_APP_API_URI}auth/login`, {
        email: username,
        password: password,
      })
      .then((response) => {
        notification.success({
          message: 'Succesfully logged in!',
          description: 'Logged in successfully, Redirecting you in a few!',
          placement: 'topRight',
          duration: 4.5,
        });
        localStorage.setItem('session_expires', response.data.jwt_expires);
        localStorage.setItem('sub', response.data.sub);
        setTimeout(() => {}, 1000);
      })
      .then(() => {
        updateState({ ...state, loading: false });
      })
      .then(() => {
        if (location.state && location.state['from']) {
          history.push(location.state['from']);
        } else {
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight',
        });
        console.log(err, err.response);
        if (err.response.data.error.code === 'UserNotConfirmedException') {
          Auth.resendSignUp(username);
          notification.info({
            message: 'You must first verify your email address',
            placement: 'topRight',
          });
          history.push(`/verify-code?email=${username}`);
        }
        if (err.response.data.error.code === 'PasswordResetRequiredException') {
          notification.info({
            message: 'Password reset required',
            placement: 'topRight',
          });
          history.push(`/forgot-password?username=${username}`);
        }
        updateState({ ...state, loading: false });
      });
  };

  // handles the onSubmit form event when validation has failed on the form fields
  const handleOnFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const { loading } = state;

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>

      <div style={{ paddingTop: '7%' }}>
        <FormWrapper onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} className="login-form">
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <h1 style={{ fontFamily: 'Brick, Arial, sand-serif', fontSize: '36px', fontWeight: 700 }}>Login</h1>
            <span>Come on in. Let's get you meeting with Myna.</span>
          </div>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input
              prefix={<UserOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              placeholder="Username"
              type="email"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input
              prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item className="text-center">
            <Row gutter={16}>
              <Col lg={24}>
                <Link style={{ float: 'right' }} className="login-form-forgot" to="/forgot-password">
                  I forgot my password
                </Link>
              </Col>
              <Col lg={24}>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  className="login-form-button"
                >
                  {loading ? (
                    <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
                  ) : (
                    'Log in'
                  )}
                </Button>
              </Col>
              {/* <Col lg={24}>
              Or <Link to="/signup">register now!</Link>
            </Col> */}
            </Row>
          </Form.Item>
        </FormWrapper>
      </div>
    </Layout>
  );
};

export default LoginContainer;
