import React, { useEffect, Fragment } from 'react';
import { Layout } from 'antd';
import queryString from 'query-string';
import api from '../../api';

const OneDriveCallbackContainer = (props: { location: any; history: any }) => {
  useEffect(() => {
    const fn = async () => {
      const { history } = props;

      const userId = localStorage.getItem('sub');
      let { code } = queryString.parse(props.location.search);
      api
        .post(`account/${userId}/onedrive/code?code=${code}`)
        .then(async () => {
          history.push('/apps');
        })
        .catch((err) => {
          console.error(err);
        });
    };
    fn();
  });

  return (
    <Fragment>
      <Layout.Content>
        <p>Loading...</p>
      </Layout.Content>
    </Fragment>
  );
};

export default OneDriveCallbackContainer;
