/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
export const validateToken = async (): Promise<boolean> => {
  try {
    // Get expires from local storage
    var expires = localStorage.getItem('session_expires');
  } catch (e) {
    return false
  }
  if (!expires) {
    return false;
  }
  try {
    return parseInt(expires) >= Date.now();
  } catch (e) {
    return false;
  }
};
