import * as React from 'react';
import { Button, Skeleton, notification } from 'antd';
import api from '../../api';
import { useParams } from 'react-router-dom';
import EditQuantity from './editQuantity';
import EditPaymentMethod from './editPaymentMethod';
import { Auth } from 'aws-amplify';
/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

const OrganisationSubscriptionContainer = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const [showSkeleton, setShowSkeleton] = React.useState(true);
  const [currentProductId, setCurrentProductId] = React.useState('');
  const [currentQuantity, setCurrentQuantity] = React.useState('');
  const [showQuantityModal, setShowQuantityModal] = React.useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = React.useState(false);

  React.useEffect(() => {
    getMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId, organisationId]);

  async function getMembership() {
    api
      .get(`organisation/${organisationId}/stripe/subscription`)
      .then((payload) => {
        if (payload.data.productId) {
          setCurrentQuantity(payload.data.quantity);
          setCurrentProductId(payload.data.productId);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        console.error(err);
        setShowSkeleton(false);
      });
  }

  const handleShowQuantityModal = async () => {
    setShowQuantityModal(true);
  };

  const handleShowEditPaymentModal = async () => {
    setShowPaymentMethodModal(true);
  };

  const handleHideQuantityModal = async () => {
    getMembership();
    setShowQuantityModal(false);
  };

  const handleHidePaymentMethodModal = async () => {
    getMembership();
    setShowPaymentMethodModal(false);
  };

  const handleCancelMembership = async () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      // Cancel
      api
        .delete(`organisation/${organisationId}`)
        .then((response) => {
          if (response.status === 204) {
            handleLogout();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleLogout = async () => {
    try {
      await api.post('/auth/logout').then(async () => {
        await Auth.signOut({ global: true }).then(() => {
          localStorage.removeItem('session_expires');
          localStorage.removeItem('sub');
          window.location.replace('https://www.meetmyna.com');
        });
      });
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <React.Fragment>
      {showSkeleton ? (
        <Skeleton />
      ) : (
        <div>
          <FormWrapper>
            <label>Membership Product: </label>
            {currentProductId}
            <br />
            <label>Quantity: </label>
            {currentQuantity}
            <br />
            <br />
            <Button onClick={() => handleShowQuantityModal()} style={{ width: '100%' }} type="primary">
              Add or Remove Licences
            </Button>
            <br />
            <br />
            <Button onClick={() => handleShowEditPaymentModal()} style={{ width: '100%' }} type="primary">
              Update Payment Method
            </Button>
            <br />
            <br />
            <br />
            <Button onClick={() => handleCancelMembership()} style={{ width: '100%' }}>
              Cancel Membership
            </Button>
            <span>Cancelling your membership will close your account with immediate effect</span>
          </FormWrapper>
          <EditQuantity
            currentQuantity={parseInt(currentQuantity)}
            organisationId={organisationId}
            showQuantityModal={showQuantityModal}
            closeHandler={handleHideQuantityModal}
          />
          <EditPaymentMethod
            organisationId={organisationId}
            showEditPaymentModal={showPaymentMethodModal}
            closeHandler={handleHidePaymentMethodModal}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default OrganisationSubscriptionContainer;
