import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/** Container components */
import TemplateContainer from '../Containers/TemplateContainer';
import DashBoardContainer from '../Containers/DashboardContainer';
import PreferencesContainer from '../Containers/PreferencesContainer';
import AppsContainer from '../Containers/AppsContainer';
import LoginContainer from '../Containers/LoginContainer';
import SignUpContainer from '../Containers/SignUpContainer';
import ConfirmEmailContainer from '../Containers/ConfirmEmailContainer';
import ForgotPasswordContainer from '../Containers/ForgotPasswordContainer';
import PasswordResetContainer from '../Containers/PasswordResetContainer';
import ZoomCallbackContainer from '../Containers/ZoomCallbackContainer';
import DropboxCallbackContainer from '../Containers/DropboxCallbackContainer';
import OneDriveCallbackContainer from '../Containers/OneDriveCallbackContainer';
import MSBusinessCallbackContainer from '../Containers/MSBusinessCallbackContainer';
import BlueJeansCallbackContainer from '../Containers/BlueJeansCallbackContainer';
//import GoogleDriveCallbackContainer from '../Containers/GoogleDriveCallbackContainer';
import OrganisationsContainer from '../Containers/OrganisationsContainer';
import OrganisationEditContainer from '../Containers/OrganisationEditContainer';
import OrganisationSubscriptionContainer from '../Containers/OrganisationSubscriptionContainer';

/** private route component */
import PrivateRoute from './PrivateRoute';

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginContainer} />
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/signup" component={SignUpContainer} />
          <Route exact path="/verify-code" component={ConfirmEmailContainer} />
          <Route exact path="/reset-password" component={PasswordResetContainer} />
          <Route exact path="/forgot-password" component={ForgotPasswordContainer} />
          <Route
            exact
            path={[
              '/dashboard',
              '/preferences',
              '/apps',
              '/zoom/callback',
              '/dropbox/callback',
              '/onedrive/callback',
              '/msbusiness/callback',
              '/bluejeans/callback',
              '/organisations',
              '/organisation/:organisationId',
              '/organisation/:organisationId/subscription',
            ]}
          >
            <TemplateContainer>
              <PrivateRoute exact path="/dashboard" component={DashBoardContainer} />
              <PrivateRoute exact path="/preferences" component={PreferencesContainer} />
              <PrivateRoute exact path="/apps" component={AppsContainer} />
              <PrivateRoute exact path="/zoom/callback" component={ZoomCallbackContainer} />
              <PrivateRoute exact path="/dropbox/callback" component={DropboxCallbackContainer} />
              <PrivateRoute exact path="/onedrive/callback" component={OneDriveCallbackContainer} />
              <PrivateRoute exact path="/msbusiness/callback" component={MSBusinessCallbackContainer} />
              <PrivateRoute exact path="/bluejeans/callback" component={BlueJeansCallbackContainer} />
              {/*<PrivateRoute exact path="/googledrive/callback" component={GoogleDriveCallbackContainer} />*/}
              <PrivateRoute exact path="/organisations" component={OrganisationsContainer} />
              <PrivateRoute exact path="/organisation/:organisationId" component={OrganisationEditContainer} />
              <PrivateRoute
                exact={true}
                path="/organisation/:organisationId/subscription"
                component={OrganisationSubscriptionContainer}
              />
            </TemplateContainer>
          </Route>
          <Route component={LoginContainer} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
