import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, notification, Spin, Col, Row, Form, Layout } from 'antd';
import axios from 'axios';

/** Presentational */
import FormWrapper from '../../Components/Styled/FormWrapper';

/** App theme */
import { colors } from '../../Themes/Colors';
// const isE164PhoneNumber = require('is-e164-phone-number');

type State = {
  confirmDirty: boolean;
  redirect: boolean;
  loading: boolean;
  email: string;
};

type Props = {
  location: any;
  history: any;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();

const SignUpContainer = (props: Props) => {
  const [state, updateState] = useState<State>({
    confirmDirty: false,
    loading: false,
    email: '',
    redirect: false,
  });

  useEffect(() => {
    const fn = async () => {
      const { location } = props;
      let { invitedEmail } = queryString.parse(location.search);
      if (typeof invitedEmail === 'string') {
        updateState({ ...state, email: invitedEmail });
      }
    };
    fn();
    //eslint-disable-next-line
  }, []);

  const [form] = Form.useForm();

  const handleOnFinish = async (values) => {
    let { fname, lname, password } = values;
    let email = state.email;

    // show loader
    updateState({ ...state, loading: true });
    const { location } = props;
    let { invitationId, organisationId, invitedEmail } = queryString.parse(location.search);
    try {
      const hasValidInvitation = await axios.get(
        `${process.env.REACT_APP_API_URI}organisation/${organisationId}/invitation/${invitationId}/email/${invitedEmail}`
      );
      if (!hasValidInvitation.data) {
        notification.error({
          message: 'Error',
          description: 'Your invite is invalid or has expired',
          placement: 'topRight',
          duration: 2.5,
        });
        updateState({ ...state, loading: false });
        return;
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'Your invite is invalid or has expired',
        placement: 'topRight',
        duration: 2.5,
      });
      updateState({ ...state, loading: false });
      return;
    }

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${fname} ${lname}`,
      },
    })
      .then(async () => {
        await axios.post(
          `${process.env.REACT_APP_API_URI}organisation/${organisationId}/invitation/${invitationId}/consume`
        );
      })
      .then(() => {
        notification.success({
          message: 'Succesfully signed up user!',
          description: 'Account created successfully, Redirecting you in a few!',
          placement: 'topRight',
          duration: 1.5,
          onClose: () => {
            updateState({ ...state, redirect: true });
          },
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Error signing up user',
          placement: 'topRight',
          duration: 1.5,
        });
        updateState({ ...state, loading: false });
      });
  };

  const handleOnfinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  const handleConfirmBlur = (event: React.FormEvent<HTMLInputElement>) => {
    updateState({ ...state, confirmDirty: state.confirmDirty || !!event.currentTarget.value });
  };

  const compareToFirstPassword = (rule: object, value: string) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error('The passwords you have entered do not match'));
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = async (rule: object, value: string) => {
    const validationRulesErrors = schema.validate(value, { list: true });
    if (validationRulesErrors.length > 0) {
      return Promise.reject(new Error(formatPasswordValidateError(validationRulesErrors)));
    } else {
      return Promise.resolve();
    }
  };

  const formatPasswordValidateError = (errors: Array<string>) => {
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === 'min') {
        return 'password length should be a at least 8 characters';
      } else if (errors[i] === 'lowercase') {
        return 'password should contain lowercase letters';
      } else if (errors[i] === 'uppercase') {
        return 'password should contain uppercase letters';
      } else if (errors[i] === 'digits') {
        return 'password should contain digits';
      } else if (errors[i] === 'symbols') {
        return 'password should contain symbols';
      }
    }
  };

  const { loading } = state;

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>
      <div style={{ paddingTop: '7%' }}>
        <FormWrapper
          id="signup"
          autoComplete="false"
          onFinish={handleOnFinish}
          onFinishFailed={handleOnfinishFailed}
          form={form}
        >
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <h1 style={{ fontFamily: 'Brick, Arial, sand-serif', fontSize: '36px', fontWeight: 700 }}>SignUp</h1>
          </div>
          <Form.Item name="fname" rules={[{ required: true, message: 'Please input your first name!' }]}>
            <Input
              prefix={<UserOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              placeholder="First Name"
              type={'text'}
              autoComplete={'chrome-off'}
            ></Input>
          </Form.Item>
          <Form.Item name="lname" rules={[{ required: true, message: 'Please input your last name!' }]}>
            <Input
              prefix={<UserOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              placeholder="Last Name"
              type={'text'}
              autoComplete={'chrome-off'}
            ></Input>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }, { validator: validatePassword }]}
          >
            <Input
              prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              type="password"
              placeholder="Password"
              autoComplete={'chrome-off new-password'}
            ></Input>
            {/* </Popover> */}
          </Form.Item>
          <Form.Item
            name="confirm"
            rules={[
              { required: true, message: 'Please confirm your password!' },
              { validator: compareToFirstPassword },
            ]}
          >
            <Input
              prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              type="password"
              placeholder="Confirm Password"
              onBlur={handleConfirmBlur}
              autoComplete={'chrome-off new-password'}
            ></Input>
          </Form.Item>
          <Form.Item className="text-center">
            <Row>
              <Col lg={24}>
                <Button style={{ width: '100%' }} type="primary" disabled={loading} htmlType="submit">
                  {loading ? (
                    <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
                  ) : (
                    'Register'
                  )}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </FormWrapper>
        {state.redirect && (
          <Redirect
            to={{
              pathname: '/verify-code',
              search: `?email=${state.email}`,
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default SignUpContainer;
