import React, { useState, useEffect, FunctionComponent } from 'react';
import { Card, Button, notification } from 'antd';
import api from '../../api';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  appName: string;
  authorizeUrl: string;
  infoLink: string;
};

const AppContainer: FunctionComponent<Props> = ({ appName, authorizeUrl, infoLink }: Props) => {
  const [connectionStatus, updateConnectionStatus] = useState('Pending');
  if (appName === 'Microsoft OneDrive (Personal)') {
    appName = 'onedrive';
  }
  if (appName === 'Microsoft Teams and OneDrive for Business') {
    appName = 'msbusiness';
  }
  var title = appName
    .split(' ')
    .map((item) => item[0].toUpperCase() + item.slice(1, item.length))
    .join(' ');
  appName = appName.toLowerCase();

  if (title === 'Onedrive') {
    title = 'Microsoft OneDrive (Personal)';
  }
  if (title === 'Msbusiness') {
    title = 'Microsoft Teams and OneDrive for Business';
  }

  useEffect(() => {
    const fn = async () => {
      const userId = localStorage.getItem('sub');

      api
        .get(`/account/${userId}/${appName}/tokens`)
        .then((response) => {
          if (response.data.access_token) {
            updateConnectionStatus('Connected');
          } else {
            updateConnectionStatus('Not Connected');
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            updateConnectionStatus('Not Connected');
          } else {
            updateConnectionStatus('Unable to get connection status');
          }
        });
    };
    fn();
    //eslint-disable-next-line
  }, []);

  const handleDisconnect = async () => {
    const userId = localStorage.getItem('sub');
    api
      .delete(`account/${userId}/${appName}`)
      .then((response) => {
        if (response.status === 204) {
          updateConnectionStatus('Not Connected');
          notification.success({
            message: 'Disconnected',
            description: `Disconnected ${appName} successfully!`,
            placement: 'topRight',
            duration: 4.5,
          });
        }
      })
      .catch((err) => {
        updateConnectionStatus('Unable to get connection status');
      });
  };

  const infoLinkButton = (
    <div>
      <a href={`${infoLink}`} rel="noopener noreferrer" target="_blank">
        <QuestionCircleOutlined translate="yes" />
      </a>
    </div>
  );
  return (
    <div style={{ paddingTop: '20px' }}>
      <Card title={`${title}`} extra={infoLinkButton}>
        <h3>Connection Status: {connectionStatus}</h3>
        {connectionStatus === 'Not Connected' ? (
          <a href={authorizeUrl}>
            <Button type="primary">Connect to {title}</Button>
          </a>
        ) : null}
        {connectionStatus === 'Connected' ? (
          <Button onClick={() => handleDisconnect()} type="primary">
            Disconnect {title}
          </Button>
        ) : null}
        {connectionStatus === 'Unable to get connection status' ? (
          <Button onClick={() => handleDisconnect()} type="primary">
            Disconnect {title}
          </Button>
        ) : null}
      </Card>
    </div>
  );
};

AppContainer.defaultProps = {
  appName: '',
  authorizeUrl: '',
};

export default AppContainer;
