import React, { useState } from 'react';
import { notification, Modal, Form, Input } from 'antd';
import api from '../../api';

type State = {
  newQuantity: number;
};

const EditQuantityContainer = (props: {
  currentQuantity: number;
  closeHandler: any;
  organisationId: string;
  showQuantityModal: boolean;
}) => {
  const [state, updateState] = useState<State>({
    newQuantity: props.currentQuantity,
  });

  const handleOnSubmit = async () => {
    const organisationId = props.organisationId;

    try {
      api
        .put(`organisation/${organisationId}/stripe/subscription/quantity`, {
          quantity: state.newQuantity,
        })
        .then((payload) => {
          if (payload.status === 200) {
            notification.success({
              message: 'Subscription Updated',
              placement: 'topRight',
              duration: 2,
              onClose: () => {
                props.closeHandler();
              },
            });
          }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateQuantityField = async (event: any) => {
    updateState({
      ...state,
      newQuantity: event.target.value,
    });
  };

  return (
    <div>
      <Modal
        title="Update Quantity"
        visible={props.showQuantityModal}
        onOk={handleOnSubmit}
        onCancel={() => props.closeHandler()}
        okText="Update"
      >
        <Form.Item>
          <Input type="number" defaultValue={props.currentQuantity} onChange={updateQuantityField} />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default EditQuantityContainer;
