import * as React from 'react';
import { Auth } from 'aws-amplify';
import api from '../../api';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  BlockOutlined,
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ClusterOutlined,
  QuestionCircleOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { Layout, Menu, notification } from 'antd';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
/** App Theme */
import { colors } from '../../Themes/Colors';

const TemplateContainer: React.SFC<RouteComponentProps> = (props, state) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const handleLogout = async () => {
    const { history } = props;
    try {
      await api.post('/auth/logout').then(async () => {
        await Auth.signOut({ global: true }).then(() => {
          localStorage.removeItem('session_expires');
          localStorage.removeItem('sub');
          history.push('/login');
        });
      });
    } catch (err) {
      notification.error({ message: err.message });
    }
  };

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>
      <Layout className="cover" id="app-header">
        <div className="logo" />
        <Layout.Sider
          className="cover"
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            borderRight: `1px solid #f0f0f0`,
          }}
        >
          <Menu mode="inline" selectedKeys={[props.location.pathname]}>
            <Menu.Item key="/dashboard">
              <HomeOutlined translate="yes" />
              <span>Home</span>
              <Link
                to={{
                  pathname: '/dashboard',
                }}
              />
            </Menu.Item>
            <Menu.Item key="/organisations">
              <ClusterOutlined translate="yes" />
              <span>Organisation</span>
              <Link
                to={{
                  pathname: '/organisations',
                }}
              />
            </Menu.Item>
            <Menu.Item key="/preferences">
              <SettingOutlined translate="yes" />
              <span>Preferences</span>
              <Link
                to={{
                  pathname: '/preferences',
                }}
              />
            </Menu.Item>
            <Menu.Item key="/apps">
              <BlockOutlined translate="yes" />
              <span>My Apps</span>
              <Link
                to={{
                  pathname: '/apps',
                }}
              />
            </Menu.Item>

            <Menu.Item key="/logout" onClick={() => handleLogout()}>
              <LogoutOutlined translate="yes" />
              <span>Logout</span>
            </Menu.Item>
            <hr />
            <Menu.Item key="/test1">
              <RocketOutlined translate="yes" />
              <span>Get Started</span>
              <Link
                to={{
                  pathname: 'https://support.meetmyna.com/hc/en-us/articles/360015898160-Start-here-Login-to-Myna-/',
                }}
                rel="noopener noreferrer"
                target="_blank"
              />
            </Menu.Item>
            <Menu.Item key="test2">
              <QuestionCircleOutlined translate="yes" />
              <span>Support</span>
              <Link
                to={{ pathname: 'https://support.meetmyna.com/hc/en-us/' }}
                rel="noopener noreferrer"
                target="_blank"
              />
            </Menu.Item>
            <hr />
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ background: colors.white, padding: 0 }}>
            {collapsed ? (
              <MenuUnfoldOutlined
                className="trigger"
                style={{ marginLeft: '15px' }}
                translate="yes"
                onClick={() => setCollapsed(!collapsed)}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger"
                style={{ marginLeft: '15px' }}
                translate="yes"
                onClick={() => setCollapsed(!collapsed)}
              />
            )}
          </Layout.Header>
          <div
            style={{
              margin: '24px 16px',
              padding: 24,
              background: colors.white,
              minHeight: '240px !important',
            }}
          >
            {props.children}
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default withRouter(TemplateContainer);
