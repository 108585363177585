/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FunctionComponent } from 'react';
import api from '../../api';
import { Button, Descriptions, Table, Modal, Form, Input, notification, Space, Tooltip, List } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import FormWrapper from '../../Components/Styled/FormWrapper';
import { CopyOutlined } from '@ant-design/icons';

type State = {
  inviteEmail: string;
  showInviteModal: boolean;
  showApiTokenModal: boolean;
  organisationDetail: any;
  userId: string;
  organisationMembers: any;
  product: any;
  licenses: number;
  availableLicenses: number;
  organisationInvitations: Array<object>;
  apiToken: string;
};

const OrganisationEditContainer: FunctionComponent = (params: any) => {
  const history = useHistory();
  const { organisationId } = useParams<{ organisationId: string }>();
  const [state, updateState] = useState<State>({
    inviteEmail: '',
    showInviteModal: false,
    showApiTokenModal: false,
    userId: '',
    organisationDetail: {},
    organisationMembers: [],
    product: '',
    licenses: 0,
    availableLicenses: 0,
    organisationInvitations: [],
    apiToken: '',
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: ['Attributes', 'name'],
    },
    {
      title: 'Email',
      dataIndex: ['Attributes', 'email'],
    },
    {
      title: 'Action',
      dataIndex: ['Attributes', 'sub'],
      render: (text, record) => (
        <>
          <Space size="middle">
            <a onClick={() => null}>Remove</a>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    getOrganisationDetailAndMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganisationDetailAndMembership = async () => {
    try {
      var userId = localStorage.getItem('sub');
      var organisationDetail = await api.get(`/organisation/${organisationId}`);
      var organisationMembers = await api.get(`/organisation/${organisationId}/members`);
      var organisationInvitations = await api.get(`/organisation/${organisationId}/invitations`);
      var membership = await api.get(`organisation/${organisationId}/stripe/subscription`);
    } catch (err) {
      console.error(err);
    }
    updateState({
      ...state,
      organisationDetail: organisationDetail.data,
      userId,
      organisationMembers: organisationMembers.data,
      organisationInvitations: organisationInvitations.data,
      product: membership ? membership.data.productId : 'UNLICENCED',
      licenses: membership ? membership.data.quantity : 0,
      availableLicenses:
        membership && organisationMembers && organisationInvitations
          ? membership.data.quantity - organisationMembers.data.length - organisationInvitations.data.length
          : 0,
    });
  };

  const handleManageSubscription = async () => {
    history.push(`/organisation/${organisationId}/subscription`);
  };

  const handleInviteUser = async () => {
    if (state.availableLicenses <= 0) {
      notification.error({
        message: "Your organisation doesn't have any more licences",
        placement: 'topRight',
        duration: 4.5,
      });
      return;
    }
    updateState({
      ...state,
      showInviteModal: true,
    });
  };

  const handleApiToken = async () => {
    updateState({
      ...state,
      showApiTokenModal: true,
    });
  };

  const handleCancelModal = async () => {
    updateState({
      ...state,
      showInviteModal: false,
      inviteEmail: '',
    });
  };

  const handleCancelApiTokenModal = async () => {
    updateState({
      ...state,
      showApiTokenModal: false,
    });
  };

  const updateInviteEmail = async (event: any) => {
    updateState({
      ...state,
      inviteEmail: event.target.value,
    });
  };

  const handleSubmitModal = async () => {
    try {
      const payload = await api.post(`organisation/${organisationId}/invite`, {
        inviteEmail: state.inviteEmail,
      });
      if (payload.status === 204) {
        notification.success({
          message: 'User Invited',
          placement: 'topRight',
          duration: 4.5,
        });
        updateState({
          ...state,
          showInviteModal: false,
          inviteEmail: '',
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        notification.error({
          message: err.response.data.message,
          placement: 'topRight',
          duration: 4.5,
        });
      } else {
        notification.error({
          message: 'An error has occurred.',
          placement: 'topRight',
          duration: 4.5,
        });
      }
    }
  };

  const handleSubmitApiTokenModal = async () => {
    try {
      api.get(`organisation/${organisationId}/jwt`).then((payload) => {
        if (payload.status === 200) {
          const token = payload.data.jwt;
          updateState({
            ...state,
            apiToken: token,
          });
          notification.success({
            message: 'Token Generated',
            placement: 'topRight',
            duration: 2,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        title="Invite User"
        visible={state.showInviteModal}
        onOk={handleSubmitModal}
        onCancel={handleCancelModal}
        okText="Invite"
      >
        <Form.Item>
          <Input type="email" placeholder="Enter email address" onChange={updateInviteEmail} />
        </Form.Item>
      </Modal>
      <Modal
        title="API Token"
        visible={state.showApiTokenModal}
        onOk={handleSubmitApiTokenModal}
        onCancel={handleCancelApiTokenModal}
        okText="Get API Token"
      >
        <Form.Item id="apiTokenForm">
          <Input
            type="text"
            placeholder="API Token"
            name="apiToken"
            disabled={true}
            value={state.apiToken}
            style={{ width: '90%' }}
          />
          <Tooltip title="Copy">
            <a onClick={() => navigator.clipboard.writeText(state.apiToken)} style={{ margin: '0 8px' }}>
              <CopyOutlined translate="yes" />
            </a>
          </Tooltip>
        </Form.Item>
      </Modal>
      <Descriptions title={state.organisationDetail.name}>
        <Descriptions.Item label="Owner Name">{state.organisationDetail.ownerName}</Descriptions.Item>
        <Descriptions.Item label="Organisation ID">{state.organisationDetail.id}</Descriptions.Item>
        <Descriptions.Item label="Product">{state.product}</Descriptions.Item>
        <Descriptions.Item label="Total Licenses">{state.licenses}</Descriptions.Item>
        <Descriptions.Item label="Available Licenses">{state.availableLicenses}</Descriptions.Item>
      </Descriptions>
      <br />
      <FormWrapper>
        <Button onClick={() => handleManageSubscription()} style={{ width: '100%' }} type="primary">
          Manage Subscription
        </Button>
        <br />
        <br />
        <Button onClick={() => handleInviteUser()} style={{ width: '100%' }} type="primary">
          Invite Users
        </Button>
        <br />
        <br />
        <Button onClick={() => handleApiToken()} style={{ width: '100%' }} type="primary">
          API Token
        </Button>
        <br />
      </FormWrapper>
      <br />
      <h2>Users</h2>
      <Table rowKey={(record) => record.Username} dataSource={state.organisationMembers} columns={columns} />
      <h2>Invited Users</h2>
      <List
        itemLayout="horizontal"
        dataSource={state.organisationInvitations}
        renderItem={(item) => (
          <List.Item
          // actions={[<a onClick={() => null}>Revoke</a>]}
          >
            <List.Item.Meta title={item['emailAddress']} />
          </List.Item>
        )}
      />
    </div>
  );
};

export default OrganisationEditContainer;
