import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Input, Button, notification, Spin, Row, Col, Form, Layout } from 'antd';

/** App theme */
import { colors } from '../../Themes/Colors';
import FormWrapper from '../../Components/Styled/FormWrapper';

type Props = RouteComponentProps & {
  form: any;
};

type State = {
  confirmDirty: boolean;
  redirect: boolean;
  loading: boolean;
};

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols();
const PasswordResetContainer = (props: Props) => {
  const [state, updateState] = useState<State>({
    confirmDirty: false,
    redirect: false,
    loading: false,
  });

  const [form] = Form.useForm();
  const history = useHistory();

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    updateState({ ...state, confirmDirty: state.confirmDirty || !!value });
  };

  const compareToFirstPassword = (rule: object, value: string) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(new Error('The passwords you have entered do not match'));
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = async (rule: object, value: string) => {
    const validationRulesErrors = schema.validate(value, { list: true });
    if (validationRulesErrors.length > 0) {
      return Promise.reject(new Error(formatPasswordValidateError(validationRulesErrors)));
    } else {
      return Promise.resolve();
    }
  };

  const formatPasswordValidateError = (errors: Array<string>) => {
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === 'min') {
        return 'password length should be a at least 8 characters';
      } else if (errors[i] === 'lowercase') {
        return 'password should contain lowercase letters';
      } else if (errors[i] === 'uppercase') {
        return 'password should contain uppercase letters';
      } else if (errors[i] === 'digits') {
        return 'password should contain digits';
      } else if (errors[i] === 'symbols') {
        return 'password should contain symbols';
      }
    }
  };

  const handleOnFinish = (values) => {
    let { password, code } = values;
    let username = props.location.search.split('=')[1];
    Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
      .then(() => {
        notification.success({
          message: 'Success!',
          description: 'Password reset successful, Redirecting you in a few!',
          placement: 'topRight',
          duration: 2,
          onClose: () => {
            history.push('/login');
          },
        });
      })
      .catch((err) => {
        notification['error']({
          message: 'Error reseting password',
          description: err.message,
          placement: 'topRight',
          duration: 4.5,
        });

        updateState({ ...state, loading: false });
      });

    // show loader
    updateState({ ...state, loading: true });
  };

  const handleOnFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  const { loading } = state;

  return (
    <Layout>
      <Layout.Header
        style={{
          background: colors.white,
          borderBottom: `1px solid #f0f0f0`,
        }}
      >
        <a href="/dashboard">
          <img alt="logo" src="https://www.meetmyna.com/images/myna.svg" />
        </a>
      </Layout.Header>
      <div style={{ paddingTop: '7%' }}>
        <FormWrapper onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed} form={form}>
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <h1 style={{ fontFamily: 'Brick, Arial, sand-serif', fontSize: '36px', fontWeight: 700 }}>
              Reset Password
            </h1>
            <span>Check your email for the confirmation code</span>
          </div>
          <Row>
            <Col lg={24}>
              <Form.Item name="code" rules={[{ required: true, message: 'Please input your confirmation code!' }]}>
                <Input
                  prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
                  placeholder="Enter your verification code"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }, { validator: validatePassword }]}
          >
            {/* <Popover placement="right" title={title} content={passwordPolicyContent} trigger="focus"> */}
            <Input
              prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
              type="password"
              placeholder="New Password"
            />
            {/* </Popover> */}
          </Form.Item>
          <Row>
            <Col lg={24}>
              <Form.Item
                name="confirm"
                rules={[
                  { required: true, message: 'Please confirm your password!' },
                  { validator: compareToFirstPassword },
                ]}
              >
                <Input
                  prefix={<LockOutlined translate="yes" style={{ color: colors.transparentBlack }} />}
                  type="password"
                  placeholder="Confirm Password"
                  onBlur={handleBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="text-center">
            <Row>
              <Col lg={24}>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" className="login-form-button">
                  {loading ? (
                    <Spin indicator={<LoadingOutlined translate="yes" style={{ fontSize: 24 }} spin />} />
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </FormWrapper>
      </div>
    </Layout>
  );
};

export default PasswordResetContainer;
