import React, { FunctionComponent } from 'react';
import { withRouter } from 'react-router';
import AppContainer from '../AppContainer/AppContainer';
import { Divider } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const AppsContainer: FunctionComponent = () => (
  <div>
    <h2>Cloud Storage</h2>
    <span>
      You will need to connect a cloud storage service to use Myna
      <a
        style={{ margin: '0 8px' }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://support.meetmyna.com/hc/en-us/articles/360015536360-Why-do-I-need-to-connect-Cloud-Storage-"
      >
        <QuestionCircleOutlined translate="yes" />
      </a>
    </span>
    <AppContainer
      appName="dropbox"
      authorizeUrl={process.env.REACT_APP_DROPBOX_CALLBACK_URI}
      infoLink="https://support.meetmyna.com/hc/en-us/articles/360014577460-Dropbox-Recording-using-Myna"
    />
    <AppContainer
      appName="Microsoft OneDrive (Personal)"
      authorizeUrl={process.env.REACT_APP_ONEDRIVE_CALLBACK_URI}
      infoLink=""
    />
    <AppContainer
      appName="Microsoft Teams and OneDrive for Business"
      authorizeUrl={process.env.REACT_APP_MSBUSINESS_CALLBACK_URI}
      infoLink=""
    />

    <Divider />
    <h2>Cloud Recordings</h2>
    <AppContainer
      appName="zoom"
      authorizeUrl={process.env.REACT_APP_ZOOM_CALLBACK_URI}
      infoLink="https://support.meetmyna.com/hc/en-us/articles/360014571400-Zoom-Set-up-Cloud-Recording"
    />
    {/*<AppContainer
      appName="bluejeans"
      authorizeUrl={process.env.REACT_APP_BLUEJEANS_CALLBACK_URI}
      infoLink=""
    />*/}
    {/*<AppContainer appName="googledrive" authorizeUrl={process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URI} />*/}
  </div>
);

export default withRouter(AppsContainer);
