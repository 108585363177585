import * as React from 'react';
import { Layout } from 'antd';
import { Route, Redirect } from 'react-router-dom';

/** Helpers */
import { validateToken } from '../Utils/Helpers';

type State = {
  finsihedLoading: boolean;
  hasAuth: boolean;
};

const PrivateRoute = ({ component: Component, ...rest }: any & { component: any }) => {
  const [state, updateState] = React.useState<State>({
    finsihedLoading: false,
    hasAuth: false,
  });

  React.useEffect(() => {
    const getAuth = async () => {
      const hasAuth = await validateToken();
      if (hasAuth) {
        updateState({ finsihedLoading: true, hasAuth: true });
      } else {
        updateState({ finsihedLoading: true, hasAuth: false });
      }
    };
    getAuth();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return state.finsihedLoading ? (
          state.hasAuth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <React.Fragment>
            <Layout.Content>
              <p>Loading...</p>
            </Layout.Content>
          </React.Fragment>
        );
      }}
    />
  );
};

export default PrivateRoute;
