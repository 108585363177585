import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/** Presentational */
import App from './Components/App';
import { GlobalStyles } from './global';

/** Amplify config */
import awsconfig from './aws-exports';

/** Service worker */
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://817aea3fab374152bddc33318355f11c@o239666.ingest.sentry.io/5300251',
  environment: process.env.REACT_APP_ENV,
});
/** Configure amplify */
Amplify.configure(awsconfig);

/** Configure Stripe */
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, {
  apiVersion: process.env.REACT_APP_STRIPE_API_VERSION,
  locale: 'auto',
});

ReactDOM.render(
  <>
    <Elements stripe={stripePromise}>
      <GlobalStyles />
      <App />
    </Elements>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
