import React, { useState, useEffect, Fragment } from 'react';
import { Descriptions } from 'antd';
import api from '../../api';
import countdown from 'countdown';

const DashboardContainer = () => {
  const [quota, setQuota] = useState('0.0000');

  useEffect(() => {
    const getQuota = async () => {
      const userId = localStorage.getItem('sub');
      api.get(`/account/${userId}/quota/`).then((payload) => {
        setQuota(`${payload.data.quota}`);
      });
    };
    getQuota();
  }, []);

  return (
    <Fragment>
      <Descriptions title="User Info" bordered>
        {/* <Descriptions.Item label="Subscription Status" span={3}>
          <Badge status="processing" text="Active" />
        </Descriptions.Item> */}
        <Descriptions.Item label="Quota Available" span={3}>
          {countdown(
            new Date().getTime() + parseInt(quota) * 1000,
            null,
            countdown.HOURS | countdown.MINUTES
          ).toString()}
        </Descriptions.Item>
      </Descriptions>
    </Fragment>
  );
};

export default DashboardContainer;
