import React, { useState, useEffect, FunctionComponent } from 'react';
import api from '../../api';
import { Card, Button } from 'antd';
import { useHistory } from 'react-router-dom';

type State = {
  organisationDetail: any;
  userId: string;
};

interface OrganisationId {
  organisationId: string;
}

const OrganisationCard: FunctionComponent<OrganisationId> = (params: any) => {
  const history = useHistory();
  const [state, updateState] = useState<State>({
    userId: '',
    organisationDetail: {},
  });

  useEffect(() => {
    const getOrganisationDetail = async () => {
      const userId = localStorage.getItem('sub');
      api.get(`/organisation/${params.organisationId}`).then((payload) => {
        updateState({
          ...state,
          organisationDetail: payload.data,
          userId,
        });
      });
    };
    getOrganisationDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = async () => {
    history.push(`/organisation/${state.organisationDetail.id}`);
  };

  return (
    <div>
      {/* If is owner */}
      {state.organisationDetail.ownerId === state.userId ? (
        <Card title={state.organisationDetail.name}>
          <Button onClick={() => handleEdit()} type="primary">
            Edit Organisation
          </Button>
        </Card>
      ) : (
        <Card title={state.organisationDetail.name}>
          Your organisation is managed by {state.organisationDetail.ownerName}
        </Card>
      )}
      <br />
    </div>
  );
};

export default OrganisationCard;
