import React, { useState } from 'react';
import { notification, Modal, Form } from 'antd';
import api from '../../api';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

type State = {
  newQuantity: number;
  loading: boolean;
};

const EditPaymentMethodContainer = (props: {
  closeHandler: any;
  organisationId: string;
  showEditPaymentModal: boolean;
}) => {
  const [state, updateState] = useState<State>({
    newQuantity: 1,
    loading: false,
  });
  const stripe = useStripe();
  const elements = useElements();

  const createPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement == null) return;

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    console.log('[PaymentMethod]', payload);
    if (payload.error) {
      updateState({ ...state, loading: false });
      notification.error({
        message: 'Error Creating Payment Method',
        placement: 'topRight',
        duration: 4.5,
      });
    } else {
      notification.success({
        message: 'Payment Method Created',
        description: 'Updating Subscription...',
        placement: 'topRight',
        duration: 4.5,
      });
      return payload.paymentMethod.id;
    }
  };

  const handleOnSubmit = async () => {
    const organisationId = props.organisationId;

    try {
      updateState({ ...state, loading: true });
      const paymentMethodId = await createPaymentMethod();
      api
        .post(`organisation/${organisationId}/stripe/subscription/paymentmethod`, {
          paymentMethodId: paymentMethodId,
        })
        .then((payload) => {
          if (payload.status === 204) {
            notification.success({
              message: 'Subscription Updated',
              placement: 'topRight',
              duration: 2,
              onClose: () => {
                props.closeHandler();
              },
            });
          }
        });
    } catch (error) {
      updateState({ ...state, loading: false });
    }
  };

  return (
    <div>
      <Modal
        title="Update Payment Method"
        visible={props.showEditPaymentModal}
        onOk={handleOnSubmit}
        onCancel={() => props.closeHandler()}
        okText="Update"
      >
        <Form.Item>
          <label>
            Card details:
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </label>
        </Form.Item>
      </Modal>
    </div>
  );
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      LoadingOutlinedColor: '#fa755a',
    },
  },
};

export default EditPaymentMethodContainer;
