import axios from 'axios';
import axiosRetry from 'axios-retry';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

axiosRetry(instance, { retries: 2 });

instance.interceptors.request.use(
    async function (config) {
        config.headers.authorization = "cookie";
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default instance;
