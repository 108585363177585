import React, { useState, useEffect, FunctionComponent } from 'react';
import api from '../../api';
import { Skeleton } from 'antd';
import OrganisationCard from '../OrganisationCard';

type State = {
  organisations: Array<string>;
  showModal: boolean;
  organisationName: string;
};

const OrganisationsContainer: FunctionComponent = () => {
  const [state, setState] = useState<State>({
    organisations: [],
    showModal: false,
    organisationName: '',
  });

  useEffect(() => {
    const getOrganisationsAndInvitations = async () => {
      const userId = localStorage.getItem('sub');
      const myOrganisations = await api.get(`/account/${userId}/organisations/`);
      setState({
        ...state,
        organisations: myOrganisations.data,
      });
    };
    getOrganisationsAndInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {state.organisations ? (
        state.organisations.map((item, key) => <OrganisationCard key={key} organisationId={item} />)
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default OrganisationsContainer;
